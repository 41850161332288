import dayjs from "dayjs";
import { useUnlimitedMembership } from "../../Hooks/useUnlimitedMembership";
import { usePastOrders } from "../../Networking/PastOrdersContext";
import { useMemo } from "react";
import { useSettings } from "../../Networking/useSettings";

const ignoreInUnlimitedCapCheckTag = "ignore-in-unlimited-cap-check";

interface UnlimitedCap {
  orderedDishes?: number;
  maxDishes?: number;
}

export const useUnlimitedCap = (): UnlimitedCap => {
  const { unlimitedMaxNumberOfDishesPerPeriod } = useSettings();
  const { orders } = usePastOrders();
  const unlimitedMembership = useUnlimitedMembership();

  const totalOrderedDishesInCurrentPeriod = useMemo(() => {
    if (!unlimitedMembership?.currentPeriodStart) {
      return undefined;
    }
    const ordersInCurrentPeriod = orders?.filter(
      (order) =>
        !order.cancelledAt &&
        order.expectedDeliveryDate &&
        !order.tags.includes(ignoreInUnlimitedCapCheckTag) &&
        dayjs(order.expectedDeliveryDate).isAfter(
          dayjs(unlimitedMembership.currentPeriodStart).subtract(1, "day"),
        ) &&
        dayjs(order.expectedDeliveryDate).isBefore(
          unlimitedMembership.validUntil,
        ),
    );
    return ordersInCurrentPeriod?.reduce((total, order) => {
      const lineItemsTotal = order.lineItems.reduce((lineTotal, item) => {
        return lineTotal + item.quantity;
      }, 0);
      return total + lineItemsTotal;
    }, 0);
  }, [orders, unlimitedMembership]);

  return {
    orderedDishes: totalOrderedDishesInCurrentPeriod,
    maxDishes: unlimitedMaxNumberOfDishesPerPeriod,
  };
};
